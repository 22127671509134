import { Stop } from "../models/stop";

const OVERLY_SPECIFIC_GOOGLE_PLACE_TYPES = [
  "premise",
  "subpremise",
  "street_address"
];

export function descriptionName(placeResult: google.maps.places.PlaceResult) {
  if (placeResult.types 
    && placeResult.types.length == 1 
    && OVERLY_SPECIFIC_GOOGLE_PLACE_TYPES.includes(placeResult.types[0])
    && placeResult.address_components
    && placeResult.address_components.length) {

    // it's probably a residential address, and we have the components, let's come up with something better.

    const countryComponent = placeResult.address_components.find((addressComponent) => {
      const types = addressComponent.types || [];
      return types.includes("country") && types.includes("political");
    });

    if (countryComponent?.short_name != "US") {
      console.log("The location information is not in an address format that we currently understand, so we may not interpret this correctly. If you're reading this, please email the following JSON to info+addresses@hillwoodpark.com, and we'll do our best to handle this better in the future.");
      console.log(JSON.stringify({address_components: placeResult.address_components, formatted_address: placeResult.formatted_address}, null, 2));
    }

    const cityComponent = placeResult.address_components.find((addressComponent) => {
      const types = addressComponent.types || [];
      return types.includes("locality") && types.includes("political");
    });

    const stateComponent = placeResult.address_components.find((addressComponent) => {
      const types = addressComponent.types || [];
      return types.includes("administrative_area_level_1") && types.includes("political");
    });

    const cityName = cityComponent?.long_name;
    const stateName = stateComponent?.long_name;
    const countryName = countryComponent?.short_name == "US" ? "USA" : countryComponent?.short_name;

    if (   cityName    && stateName    && countryName    ) return `${cityName}, ${stateName}, ${countryName}`; // Yay! This will be good.
    if (   cityName    && stateName /* && countryName */ ) return `${cityName}, ${stateName}`;    // This probably works for the US, and MAYBE elsewhere.
    if (   cityName /* && stateName */ && countryName    ) return `${cityName}, ${countryName}`;  // This will work for big, well-know cities, but...
    if (/* cityName && */ stateName    && countryName    ) return `${stateName}, ${countryName}`; // This will work for small states and provinces, but...
    if (   cityName /* && stateName    && countryName */ ) return `${cityName}`; // This will work for well-known unique cities (e.g. Rome), but..
    if (/* cityName && */ stateName /* && countryName */ ) return `${stateName}`; // This will work pretty well, I suspect. But also might be weird.
    if (/* cityName &&    stateName && */ countryName    ) return `${countryName}`; // This will work pretty well, I suspect. But also might be weird.

  }

  return placeResult.name;
}

export function navigationName(placeResult: google.maps.places.PlaceResult, userProvidedName: string | undefined) {
  return placeResult.formatted_address || userProvidedName || placeResult.name;
};

export function alertDescriptionForStop(stop: Stop): string {
  const arrival = stop.arrivalDateTime ? new Date(stop.arrivalDateTime).toLocaleDateString() : undefined;
  const departure = stop.departureDateTime ? new Date(stop.departureDateTime).toLocaleDateString() : undefined;
  const title = stop.title || stop.userProvidedName || "my stop's location";

  if (arrival && departure) {
    if (arrival == departure) {
      return `Send me occasional email alerts about events and activities that are occuring near ${title} on ${arrival}.`
    } else {
      return `Send me occasional email alerts about events and activities that are occuring near ${title} between ${arrival} and ${departure}.`
    }

  } else if (arrival) {
    return `Send me occasional email alerts about events and activities that are occuring near ${title} shortly after I arrive on ${arrival}.`
  } else if (departure) {
    return `Send me occasional email alerts about events and activities that are occuring near ${title} before I depart on ${departure}.`
  }


  return `Send me email alerts about events and activities near ${title}.`        
}

